import React, { useState } from "react";
import supabase from "../supabaseClient.js";

const BUTTON_STYLES = "font-bold py-2 px-4 rounded-lg text-lg shadow-md whitespace-nowrap"; // Added px-6 and whitespace-nowrap
const BLUE_BUTTON = `bg-blue-400 hover:bg-blue-500 text-white ${BUTTON_STYLES}`;
const GREEN_BUTTON = `bg-green-400 hover:bg-green-500 text-white ${BUTTON_STYLES}`;

function ReferralGenerator({
  businessName,
  businessId,
  prefix,
  background_color,
}) {
  const [generatedCode, setGeneratedCode] = useState("");
  const [showTextBox, setShowTextBox] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [amount, setAmount] = useState("");
  const [codeStatus, setCodeStatus] = useState("unchecked"); // "unchecked" | "success" | "failure"
  const [showSignUp, setShowSignUp] = useState(false); // New state for SignUp component visibility
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  async function handleGenerateCode() {
    const codeName = `${prefix}${Math.floor(Math.random() * 1000) + 1}`;
    setGeneratedCode(codeName);
    try {
      const { data, error } = await supabase
        .from("referral_codes")
        .insert([{ business_id: businessId, code_name: codeName }]);
      if (error) throw error;
      console.log("Code added:", data);
    } catch (error) {
      console.error("Error adding code:", error);
    }
    setShowTextBox(false);
  }

  async function handleSignUp() {
    if (!firstName || !lastName || !email) {
      alert("Please fill all fields before submitting!");
      return;
    }

    try {
      const { data, error } = await supabase.from("user_signups").insert([
        {
          first_name: firstName,
          last_name: lastName,
          business_id: businessId,
          email: email,
        },
      ]);

      if (error) throw error;
      console.log("User added:", data);
    } catch (error) {
      console.error("Error adding user:", error);
    }
  }

  function handleSignUp() {
    setShowSignUp(!showSignUp);
  }

  function handleAcceptCode() {
    setGeneratedCode("");
    setShowTextBox(true);
    setCodeStatus("unchecked"); // Reset the status when the textbox is shown
  }

  async function handleEnter() {
    const { data, error } = await supabase
      .from("referral_codes")
      .select("id, code_name, count_used, amount")
      .eq("code_name", inputValue)
      .eq("business_id", businessId);

    if (error) {
      console.error("Error checking code:", error);
      return;
    }

    if (data.length) {
      setCodeStatus("success");
      setShowTextBox(false);

      const codeId = data[0].id;

      // Verify amount value
      if (isNaN(amount) || amount.trim() === "") {
        console.error("Invalid amount provided");
        return;
      }

      const currentCount = data[0].count_used || 0;
      const currentAmount = data[0].amount || 0;
      const newAmount = currentAmount + parseFloat(amount);

      try {
        const { error: updateError } = await supabase
          .from("referral_codes")
          .update({
            count_used: currentCount + 1,
            amount: newAmount,
          })
          .eq("code_name", inputValue);

        if (updateError) {
          console.error("Error updating count and amount:", updateError);
        } else {
          console.log("Count and amount updated successfully.");

          // Record code usage
          const { error: usageError } = await supabase
            .from("code_usage")
            .insert([
              {
                code_id: codeId,
                amount: parseFloat(amount),
                created_at: new Date(),
              },
            ]);

          if (usageError) {
            console.error("Error recording code usage:", usageError);
          } else {
            console.log("Code usage recorded successfully.");
          }
        }
      } catch (error) {
        console.error("Error updating count and amount:", error);
      }
    } else {
      setCodeStatus("failure");
    }
  }

  return (
    <div
      className={`h-screen bg-gradient-to-br ${background_color} flex flex-col items-center justify-center`}
    >
      <div className="bg-white p-8 rounded-lg shadow-xl w-full max-w-lg">
        <Greeting businessName={businessName} />
        <ActionButtons
          onSignUp={handleSignUp}
          onGenerate={handleGenerateCode}
          onAccept={handleAcceptCode}
        />
        {generatedCode && <GeneratedCodeDisplay code={generatedCode} />}
        {showTextBox && (
          <div className="mt-4">
            <CodeInputBox
              value={inputValue}
              onChange={setInputValue}
              status={codeStatus}
            />
            <AmountInput value={amount} onChange={setAmount} />
            <button className={`mt-3 ${GREEN_BUTTON}`} onClick={handleEnter}>
              Enter
            </button>
            {codeStatus === "success" && (
              <p className="ml-2 mt-2 text-green-500">Success!</p>
            )}
          </div>
        )}
        {showSignUp && (
          <div className="mt-4">
            <InputField
              value={firstName}
              onChange={setFirstName}
              placeholder="First Name"
            />
            <InputField
              value={lastName}
              onChange={setLastName}
              placeholder="Last Name"
            />
            <InputField value={email} onChange={setEmail} placeholder="Email" />
            <button className={`${GREEN_BUTTON} mt-3`} onClick={handleSignUp}>
              Sign Up
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

const Greeting = ({ businessName }) => (
  <p className="text-2xl mb-5 text-center">
    Welcome, <span className="font-bold">{businessName}</span>!
  </p>
);

const ActionButtons = ({ onSignUp, onGenerate, onAccept }) => (
  <div className="flex mb-4 space-x-5 justify-center">
    <button className={`${BLUE_BUTTON}`} onClick={onSignUp}>
      {"Create User"}
    </button>
    <button className={`${BLUE_BUTTON}`} onClick={onGenerate}>
      Generate code
    </button>
    <button className={BLUE_BUTTON} onClick={onAccept}>
      Accept code
    </button>
  </div>
);

const GeneratedCodeDisplay = ({ code }) => (
  <div className="p-3 bg-gray-100 rounded-lg mb-4 text-center">
    <span className="text-lg">Code generated: </span>
    <span className="font-bold text-xl">{code}</span>
  </div>
);

const CodeInputBox = ({ value, onChange, status }) => (
  <input
    type="text"
    placeholder="Type the code here"
    value={value}
    onChange={(e) => onChange(e.target.value)}
    className={`w-full mb-3 p-2 rounded-lg border-2 text-lg ${
      status === "failure" ? "border-red-500" : "border-gray-300"
    }`}
  />
);

const AmountInput = ({ value, onChange }) => (
  <input
    type="text"
    placeholder="Amount in GBP"
    value={value}
    onChange={(e) => onChange(e.target.value)}
    className="w-full mb-3 p-2 rounded-lg border-2 text-lg border-gray-300"
  />
);

const InputField = ({ value, onChange, placeholder }) => (
  <input
    type="text"
    placeholder={placeholder}
    value={value}
    onChange={(e) => onChange(e.target.value)}
    className={`w-full mb-3 p-2 rounded-lg border-2 text-lg ${
      !value ? "border-red-500" : "border-gray-300"
    }`}
  />
);

export default ReferralGenerator;
