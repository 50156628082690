import React, { useState } from "react";
import supabase from "../supabaseClient.js";

const BUTTON_STYLES = "font-bold py-2 px-6 rounded-lg text-lg shadow-md whitespace-nowrap"; // Added px-6 and whitespace-nowrap
const GREEN_BUTTON = `bg-green-400 hover:bg-green-500 text-white ${BUTTON_STYLES}`;

function SignUp({ businessName, businessId, code_prefix, background_color }) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [isSignedUp, setIsSignedUp] = useState(false);
  const [generatedCode, setGeneratedCode] = useState("");

  async function handleGenerateCode() {
    const codeName = `${code_prefix}${Math.floor(Math.random() * 1000) + 1}`;
    try {
      const { data, error } = await supabase
        .from("referral_codes")
        .insert([
          { business_id: businessId, code_name: codeName },
        ]);
      if (error) throw error;
      setGeneratedCode(codeName);
      console.log("Code added:", data);
    } catch (error) {
      console.error("Error adding code:", error);
    }
  }

  async function handleSignUp() {
    let newErrors = {};
    if (!firstName) newErrors.firstName = true;
    if (!lastName) newErrors.lastName = true;
    if (!email || !email.includes("@")) newErrors.email = true;

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      const { data, error } = await supabase.from("user_signups").insert([
        {
          business_id: businessId,
          first_name: firstName,
          last_name: lastName,
          email: email,
          created_at: new Date(),
        },
      ]);
      const newUserId = data[0].id;
      if (error) throw error;
      setIsSignedUp(true);
      handleGenerateCode(); // Generate the referral code after successful sign up
      console.log("Member added:", data);
    } catch (error) {
      console.error("Error adding member:", error);
    }
  }

  return (
    <div
      className={`h-screen bg-gradient-to-br ${background_color} flex flex-col items-center justify-center`}
    >
      <div className="bg-white p-8 rounded-lg shadow-xl w-full max-w-lg">
        <Greeting businessName={businessName} />
        {isSignedUp ? (
          <>
            <ThankYouMessage
              businessName={businessName}
              generatedCode={generatedCode}
            />
          </>
        ) : (
          <div className="mt-4">
            <TextInput
              label="First name"
              value={firstName}
              onChange={setFirstName}
              error={errors.firstName}
            />
            <TextInput
              label="Last name"
              value={lastName}
              onChange={setLastName}
              error={errors.lastName}
            />
            <TextInput
              label="Email"
              value={email}
              onChange={setEmail}
              error={errors.email}
            />
            <button className={`mt-3 ${GREEN_BUTTON}`} onClick={handleSignUp}>
              Sign Up
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

const Greeting = ({ businessName }) => (
  <p className="text-2xl mb-5 text-center">
    Sign up to join <span className="font-bold">{businessName}</span>!
  </p>
);

const ThankYouMessage = ({ businessName, generatedCode }) => (
  <div className="text-center p-5 bg-gray-100 rounded-lg shadow-md">
    <h2 className="text-2xl font-semibold mb-4">Welcome to {businessName}!</h2>
    <p className="text-xl mb-3">We're thrilled to have you on board.</p>
    <div className="bg-green-100 p-3 mb-3 rounded-md">
      <p className="text-lg font-medium">
        Share the joy! When one of your friends uses your special code{" "}
        <span className="font-bold text-green-600">{generatedCode}</span>,
        they'll get a <span className="font-bold">10% discount</span> and you will accumulate <span className="font-bold">credits</span> to spend here.
      </p>
    </div>
    <p className="text-xl">
      Let us know when you're ready to pamper yourself - we're here to make your
      beauty experience unforgettable. <br /> <br />
      Warm regards, <br />
      Cherry Blossom Team
    </p>
  </div>
);

const TextInput = ({ label, value, onChange, error }) => (
  <div className="mb-4">
    <label className="block text-lg mb-2">{label}</label>
    <input
      type="text"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className={`w-full p-2 rounded-lg text-lg ${
        error ? "border-2 border-red-500" : "border border-gray-300"
      }`}
    />
  </div>
);

export default SignUp;
