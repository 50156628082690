import React from "react";
import UserSignUp from "./UserSignUp";

function HolyShotClub() {
  return (
    <UserSignUp
      businessName="Holy Shot Club"
      businessId="holy_shot_coffee"
      code_prefix="HSC"
      background_color="from-blue-300 via-blue-500 to-black-700"
    />
  );
}

export default HolyShotClub;
