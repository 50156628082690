import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CherryBlossom from "./customers/CherryBlossom";
import CherryClub from "./signup/CherryClub";
import BragsAndBrams from "./customers/BragsAndBrams";
import HolyShotCoffee from "./customers/HolyShotCoffee";
import HolyShotClub from "./signup/HolyShotClub";

function Home() {
  return (
    <div className="h-screen flex flex-col justify-center items-center bg-gradient-to-b from-blue-300 to-blue-500">
      <img src={logo} className="w-40 h-40 mb-5" alt="logo" />
      <h1 className="text-4xl font-bold mb-5">AURA - A new way for businesses to engage with their community.</h1>
      <p className="text-xl">Created by Ula & Ahmed</p>
    </div>
  );
}

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/cherryblossom" element={<CherryBlossom />} />
          <Route path="/cherryclub" element={<CherryClub />} />
          <Route path="/bragsbrams" element={<BragsAndBrams />} />
          <Route path="/holyshot" element={<HolyShotCoffee />} />
          <Route path="/holyshotclub" element={<HolyShotClub />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;