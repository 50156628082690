import React from "react";
import ReferralGenerator from "./ReferralGenerator";

function HolyShotCoffee() {
  return (
    <ReferralGenerator
      businessName="Holy Shot Coffee"
      businessId="holy_shot_coffee"
      prefix="HSC"
      background_color="from-blue-300 via-blue-500 to-black-700"
    />
  );
}

export default HolyShotCoffee;