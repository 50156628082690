import React from "react";
import ReferralGenerator from "./ReferralGenerator";

function CherryBlossom() {
  return (
    <ReferralGenerator
      businessName="Cherry Blossom"
      businessId="cherry_blossom"
      prefix="CB"
      background_color = "from-purple-400 via-pink-500 to-red-500"
    />
  );
}

export default CherryBlossom;
