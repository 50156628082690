import React from "react";
import UserSignUp from "./UserSignUp";

function CherryClub() {
  return (
    <UserSignUp
      businessName="Cherry Club"
      businessId="cherry_blossom"
      code_prefix="CB"
      background_color = "from-purple-400 via-pink-500 to-red-500"
    />
  );
}

export default CherryClub;
