import React from "react";
import ReferralGenerator from "./ReferralGenerator";

function BragsAndBrams() {
  return (
    <ReferralGenerator
      businessName="Brags & Brams"
      businessId="brags_bams"
      prefix="BB"
      background_color="from-blue-300 via-blue-500 to-black-700"
    />
  );
}

export default BragsAndBrams;
